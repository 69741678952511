import de from 'apexcharts/dist/locales/de.json'
import en from 'apexcharts/dist/locales/en.json'
import it from 'apexcharts/dist/locales/it.json'

const locales = {
    de,
    en,
    it,
}

const xAxisTranslation = {
    de: 'Zeit',
    en: 'Time',
    it: 'Tempo',
}

export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    locales: [locales[this.$i18n.locale] || de],
                    defaultLocale: locales[this.$i18n.locale]
                        ? this.$i18n.locale
                        : 'de',
                    toolbar: {
                        export: {
                            csv: {
                                headerCategory:
                                    xAxisTranslation[this.$i18n.locale],
                                dateFormatter(timestamp) {
                                    return timestamp
                                },
                            },
                        },
                    },
                },
            },
        }
    },
}

<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input
                v-model="customRange"
                @input="loadHistoryCustom"
            />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <apex-chart
                v-if="!isLoading"
                height="440px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const {
    VUE_APP_COLOR_PRIMARY: colorPrimary,
    VUE_APP_COLOR_ACCENT: colorAccent,
} = process.env

export default {
    name: 'AssetChartsCombinedView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataTypes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                stroke: {
                    curve: 'straight',
                },
                dataLabels: {
                    enabled: false,
                },
                colors: this.getColors(),
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.7,
                        opacityTo: 0,
                    },
                },
                yaxis: {
                    title: {
                        text: this.getYTitle(),
                    },
                    min: this.getYMinValue() || undefined,
                    max: this.getYMaxValue() || undefined,
                    ...(['bin', 'sbb-bin'].includes(this.assetType) &&
                        this.dataType === 'distance' && {
                            title: {
                                text: this.$i18n.t('yaxis_fillLevel'),
                            },
                            min: 0,
                            max: 100,
                        }),
                    ...(this.dataType === 'accel_y' && {
                        show: false,
                        tickAmount: 2,
                        min: 0,
                        max: 2,
                    }),
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    watch: {
        dataTypes() {
            this.chartOptions.colors = this.getColors()
            this.chartOptions.yaxis.title.text = this.getYTitle()
            this.chartOptions.yaxis.min = this.getYMinValue() || undefined
            this.chartOptions.yaxis.max = this.getYMaxValue() || undefined
            this.loadHistoryCustom(this.customRange)
        },
    },
    methods: {
        getColors() {
            const defaultColors = [colorAccent, colorPrimary]
            return this.dataTypes.map((item, i) => {
                switch (item) {
                    case 'humidity':
                    case 'water_level':
                        return '#0077c2'
                    case 'temperature':
                        return colorAccent
                    default:
                        return defaultColors[i]
                }
            })
        },
        getYTitle() {
            return this.dataTypes
                .map(item => this.$t('yaxis_' + item))
                .join(` ${this.$t('and')} `)
        },
        getYMinValue() {
            return Math.min(
                ...this.dataTypes.map(item => {
                    switch (item) {
                        case 'temperature':
                            return -25
                        case 'battery':
                        case 'battery_voltage':
                        case 'co2':
                        case 'distance':
                        case 'fill_level':
                        case 'humidity':
                        case 'mass':
                        case 'voc':
                        case 'volume':
                            return 0
                    }
                })
            )
        },
        getYMaxValue() {
            return Math.max(
                ...this.dataTypes.map(item => {
                    switch (item) {
                        case 'battery':
                        case 'fill_level':
                        case 'humidity':
                            return 100
                        case 'temperature':
                            return 50
                    }
                })
            )
        },
        loadHistoryCustom(dates) {
            this.loadHistoryFromDateRange({
                startDate: moment(dates.startDate),
                endDate: moment(dates.endDate),
            })
        },
        async loadHistoryFromDateRange(dates) {
            this.isLoading = true
            this.startDate = dates.startDate
            this.endDate = dates.endDate
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            const results = []
            let url = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            const chartData = this.dataTypes.map(dataType => {
                const data = results.filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        dataType
                    )
                )

                if (dataType === 'accel_y') {
                    return data.map(item => [item.timestamp, 1])
                } else if (
                    this.assetType === 'bin' &&
                    dataType === 'distance'
                ) {
                    return data.map(item => [
                        item.timestamp,
                        measurementHelper.convertToBinLevel(
                            item.sensor_data[dataType]
                        ),
                    ])
                } else if (
                    this.assetType === 'sbb-bin' &&
                    dataType === 'distance'
                ) {
                    return data.map(item => [
                        item.timestamp,
                        measurementHelper.convertToSbbBinLevel(
                            item.sensor_data[dataType]
                        ),
                    ])
                } else if (dataType === 'fill_level') {
                    return data.map(item => [
                        item.timestamp,
                        parseInt(item.sensor_data[dataType] * 100),
                    ])
                } else if (dataType === 'mass') {
                    return data.map(item => [
                        item.timestamp,
                        (item.sensor_data[dataType] / 1000).toFixed(2),
                    ])
                } else {
                    return data.map(item => [
                        item.timestamp,
                        item.sensor_data[dataType],
                    ])
                }
            })

            this.series = chartData.map((data, i) => ({
                name: this.$t(`router.${this.dataTypes[i]}`),
                data,
            }))
        },
    },
}
</script>

<i18n>
{
    "en": {
        "and": "and",
        "yaxis_battery": "Battery (%)",
        "yaxis_battery_voltage": "Battery voltage (V)",
        "yaxis_co2": "Carbon dioxide (ppm)",
        "yaxis_distance": "Distance (m)",
        "yaxis_fillLevel": "Fill level (%)",
        "yaxis_fill_level": "Fill level (%)",
        "yaxis_humidity": "Humidity (%)",
        "yaxis_lower_loop": "Lower loop",
        "yaxis_mass": "Mass (t)",
        "yaxis_potential": "Potential",
        "yaxis_temperature": "Temperature (°C)",
        "yaxis_upper_loop": "Upper loop",
        "yaxis_voc": "Volatile organic compounds (ppb)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Water level",
        "yaxis_tau": "Tau"
    },
    "de": {
        "and": "und",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_voltage": "Batteriespannung (V)",
        "yaxis_co2": "Kohlenstoffdioxid (ppm)",
        "yaxis_distance": "Distanz (m)",
        "yaxis_fillLevel": "Füllstand (%)",
        "yaxis_fill_level": "Füllstand (%)",
        "yaxis_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_lower_loop": "Untere Schlaufe",
        "yaxis_mass": "Masse (t)",
        "yaxis_potential": "Potential",
        "yaxis_temperature": "Temperatur (°C)",
        "yaxis_upper_loop": "Obere Schlaufe",
        "yaxis_voc": "Flüchtige organische Verbindungen (ppb)",
        "yaxis_volume": "Volumen (m³)",
        "yaxis_water_level": "Wasserpegel",
        "yaxis_tau": "Tau"
    },
    "it": {
        "and": "e",
        "yaxis_battery": "Batteria (%)",
        "yaxis_battery_voltage": "Tensione della batteria (V)",
        "yaxis_co2": "Anidride carbonica (ppm)",
        "yaxis_distance": "Distanza (m)",
        "yaxis_fillLevel": "Riempimento (%)",
        "yaxis_fill_level": "Riempimento (%)",
        "yaxis_humidity": "Umidità (%)",
        "yaxis_lower_loop": "Loop inferiore",
        "yaxis_mass": "Massa (t)",
        "yaxis_potential": "Potential",
        "yaxis_temperature": "Temperatura (°C)",
        "yaxis_upper_loop": "Loop superiore",
        "yaxis_voc": "Composti organici volatili (ppb)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Livello dell'acqua",
        "yaxis_tau": "Tau"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

@media only screen and (max-width: 1200px) {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}

@media only screen and (min-width: 1200px) {
    .datepicker {
        width: 20%;
        min-width: 350px;
    }
}
</style>
